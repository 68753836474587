import React from "react";
import _ from "lodash";
import styles from "./SelectObjectives.module.scss";
import { FormControl, FormHelperText, InputLabel, Select, MenuItem, Checkbox, ListItemText, Typography } from "@material-ui/core";
import PlanPill from "../PlanPill/PlanPill";

const SelectObjectives = ({
  name,
  objectives,
  handleChange,
  values,
  category,
  multiple,
  helperText,
  error,
  plansOrder = [],
  showEmpty = false,
}) => {
  let filter = [],
    tiedIn = "";
  if (category === "3 year" || category === "rock") {
    filter = ["1 year"];
    tiedIn = "1 Year";
  } else if (category === "1 year") {
    filter = ["3 year"];
    tiedIn = "3 Year";
  } else {
    filter = ["3 year", "1 year"];
  }

  return (
    <FormControl fullWidth variant="outlined" margin="normal" error={error}>
      <InputLabel>Tied In {tiedIn} Objectives</InputLabel>
      <Select
        multiple={multiple}
        value={values || ""}
        renderValue={(arr) => {
          const id = arr[0];
          return (
            <Typography variant="inherit" noWrap>
              {multiple ? `${(values || []).length} Selected Objectives` : _.get(_.find(objectives, { id }), "value", "No Tie in")}
            </Typography>
          );
        }}
        label="Tied in x year objectives"
        onChange={handleChange}
        name={name}
        error={error}
      >
        {showEmpty && (
          <MenuItem value={null} className={styles.select}>
            <Checkbox checked={(values || []).includes(null)} />
            <ListItemText>No objective tie in</ListItemText>
          </MenuItem>
        )}
        {objectives &&
          _.sortBy(objectives, [
            function (obj) {
              // this preliminary sorting step is required if the list of objectives includes multiple plans
              return plansOrder.indexOf(_.get(obj, "plan.sharedPlanId"));
            },
            "number",
          ]).map(({ id, value, category, number, plan }) => {
            if (!filter.includes(category)) return null;
            return (
              <MenuItem key={id} value={id} className={styles.select}>
                <Checkbox checked={(values || []).includes(id)} />
                <ListItemText>
                  {!_.isNil(plan) ? <PlanPill plan={plan} /> : <PlanPill plan={null} />}
                  {number}. {value}
                </ListItemText>
              </MenuItem>
            );
          })}
      </Select>
      <FormHelperText error={error}>{helperText}</FormHelperText>
    </FormControl>
  );
};

export default SelectObjectives;
