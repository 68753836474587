export const NOTIFICATION_FREQUENCY = ["daily", "weekly", "monthly", "quarterly", "yearly"];
export const isProd = process.env.REACT_APP_ENV === "PROD";

// **IMPORTANT: the keys need to be the camelCase version of their respective "value" fields
// The duration values are strings because InputStep in meetingSchema has duration as String to work with MUI textfields
// (gets converted to Number when saved in DB)
export const DEFAULT_MEETING_ACTIONS = {
  checkIn: {
    name: "Check-in",
    value: "check-in",
    duration: "5",
  },
  scorecard: {
    name: "Scorecard",
    value: "scorecard",
    duration: "5",
  },
  rocks: {
    name: "Rocks",
    value: "rocks",
    duration: "5",
  },
  textBlock: {
    name: "Text Block",
    value: "text block",
    duration: "5",
  },
  toDos: {
    name: "To-Dos",
    value: "to-dos",
    duration: "5",
  },
  issues: {
    name: "Issues",
    value: "issues",
    duration: "5",
  },
  conclude: {
    name: "Conclude",
    value: "conclude",
    duration: "5",
  },
};

export const REPEATABLE_MEETING_ACTIONS = ["textBlock"];

export const S3_SIGNED_URL_OPERATIONS = {
  upload: "putObject",
  delete: "deleteObject",
  get: "getObject",
};
