import React, { useState, useEffect, useContext, useMemo } from "react";
import { useParams, useNavigate } from "react-router-dom";
import styles from "./WeeklyReview.module.scss";
import _ from "lodash";
import { useQuery, useLazyQuery, useMutation, useSubscription } from "@apollo/client";
import gql from "graphql-tag";
import { addMinutes } from "date-fns";
import { quarterDates, addToDate, getCurrentQuarter, diffInDays } from "../../utils/dates";
import { isAuthed } from "../../utils/authorization";
import { useAuth } from "../../context/authContext";
import { DialogContext } from "../../context/dialogContext";
import { FetchContext } from "../../context/fetchContext";
import { SnackbarContext } from "../../context/snackbarContext";
import { TODO_FIELDS, USER_FIELDS, WEEKLY_TARGET_FIELDS } from "../../utils/fragments";

const useReview = () => {
  const params = useParams();
  const navigate = useNavigate();

  const { auth } = useAuth();
  const { requestFetch } = useContext(FetchContext);
  const { dialog, setDialog } = useContext(DialogContext);
  const { snack } = useContext(SnackbarContext);
  const [started, setStarted] = useState(false);
  const [isPolling, setIsPolling] = useState(false);
  const [currentTime, setCurrentTime] = useState(new Date());
  const [currentStep, setCurrentStep] = useState(null);
  const [completedSteps, setCompletedSteps] = useState([]);
  const [currentRock, setCurrentRock] = useState();
  const [completedRocks, setCompletedRocks] = useState([]);
  const [usersAttending, setUsersAttending] = useState([]);
  const { data, loading } = useQuery(GET_MEETING, { variables: { id: params.meeting_id } });
  const { data: currentSessionSubscription } = useSubscription(SUBSCRIBE_TO_CURRENT_SESSION, {
    variables: { id: params.meeting_id },
  });
  const [getUserData, { data: userData, loading: userLoading, startPolling }] = useLazyQuery(GET_DATA_FOR_USERS); //TODO: MAKE THIS A SUBSCRIPTION
  const [updateMeeting] = useMutation(UPDATE_MEETING);
  const [updateCurrentSession] = useMutation(UPDATE_CURRENT_SESSION);
  const [updateSuccessCriteria] = useMutation(UPDATE_SUCCESS_CRITERIA);
  const [rateMeeting] = useMutation(RATE_MEETING);
  const [completeMeeting] = useMutation(COMPLETE_MEETING);
  const [updateRockStatus] = useMutation(UPDATE_ROCK_STATUS);

  const currentStepAlias = useMemo(() => {
    let alias;
    if (currentStep) {
      const step = _.find(data.meeting.steps, (s) => s.name === currentStep);
      alias = _.get(step, "name");
      if (_.get(step, "alias")) alias = step.alias;
    }
    return alias;
  }, [currentStep]);

  const userRooms = useMemo(() => {
    let obj = {};
    if (data && _.has(currentSessionSubscription, "meetingSub.rooms")) {
      const { rooms } = currentSessionSubscription.meetingSub;

      Object.keys(rooms).forEach((key) => {
        obj[key] = [];

        if (key !== "__typename") {
          rooms[key].forEach((userId) => {
            const user = _.find(data.meeting.users, ({ id }) => userId === id);
            obj[key].push(user);
          });
        }
      });
    }

    return obj;
  }, [data, currentSessionSubscription]);

  const quarterInfo = useMemo(() => {
    if (_.has(userData, "organization.fiscalYear")) {
      const { fiscalYear } = userData.organization;

      const quarter = getCurrentQuarter(fiscalYear);

      const [, end] = quarterDates(addToDate(fiscalYear, { days: 1 }), quarter);
      const daysRemaining = diffInDays(end, new Date());

      return { quarter, daysRemaining };
    }

    return { quarter: null, daysRemaining: null };
  }, [userData]);

  const isAdmin = isAuthed(auth, "vantage facilitator") || auth.id === _.get(data, "meeting.owner.id");

  const updateCurrentRock = async (id) => {
    const currentSession = {
      currentRock: id,
    };
    await updateCurrentSession({ variables: { id: params.meeting_id, currentSession } });
  };

  const formatAsTime = (seconds) => {
    let time, status;
    if (seconds <= 0) {
      time = Math.abs(seconds);
      status = "error";
    } else {
      time = seconds;
      if (seconds <= 60) {
        status = "warning";
      }
    }
    const hours = Math.floor(time / 60 / 60);
    const mins = Math.floor(time / 60) - hours * 60;
    const secs = time % 60;

    if (status) {
      return (
        <span className={status === "error" ? styles.delete : styles.warning}>
          {seconds < 0 && "-"}
          {mins.toString().padStart(2, "0") + ":" + secs.toString().padStart(2, "0")}
        </span>
      );
    }
    return <span>{mins.toString().padStart(2, "0") + ":" + secs.toString().padStart(2, "0")}</span>;
  };

  const handleChangeStep = (name) => async () => {
    if (!started) return null;
    if (isAdmin) {
      //only meeting owner updates steps
      const nextStep = _.find(data.meeting.steps, (s) => s.name === name);
      const currentSession = { currentStep: name, currentStepEndTime: addMinutes(new Date(), nextStep.duration) };

      await updateCurrentSession({ variables: { id: params.meeting_id, currentSession } });
    }
    setCurrentStep(name);
  };

  const handleStepButton = (inc) => () => {
    if (!started) return null;
    const indexOfCurr = data.meeting.steps.findIndex(({ name }) => name === currentStep);
    const { name } = data.meeting.steps[indexOfCurr + inc];
    handleChangeStep(name)();
  };

  const handleAddDialog =
    (category, referenceModel = null, referenceId = null) =>
    () => {
      setDialog({ ...dialog, addTodoDialog: { open: true, category, referenceId, referenceModel } });
    };

  const handleAddScDialog = (id) => () => {
    setDialog({ ...dialog, addSuccessCriteriaDialog: id });
  };

  // Get all the data for users attending the meeting
  const handleStart = async () => {
    setCurrentStep(data.meeting.steps[0].name);

    const currentSession = {
      endTime: addMinutes(
        new Date(),
        data.meeting.steps.reduce((sum, { duration }) => sum + duration, 0)
      ),
      currentStep: data.meeting.steps[0].name,
      currentStepEndTime: addMinutes(new Date(), data.meeting.steps[0].duration),
      currentRock: null,
      users: usersAttending,
    };

    await updateMeeting({ variables: { id: params.meeting_id, status: "in progress" } });
    await updateCurrentSession({ variables: { id: params.meeting_id, currentSession } });
    setStarted(true);
  };

  const handleUsersAttending = (id) => () => {
    const currentIndex = usersAttending.indexOf(id);
    const newUsersAttending = [...usersAttending];

    if (currentIndex === -1) {
      newUsersAttending.push(id);
    } else {
      newUsersAttending.splice(currentIndex, 1);
    }

    setUsersAttending(newUsersAttending);
  };

  const handleExit = (rating) => async () => {
    if (isAdmin) {
      await rateMeeting({ variables: { id: params.meeting_id, rating } });
      const { issues, rocks, todos, weeklyTargets } = userData;
      const issueIds = [],
        todoIds = [],
        rockIds = [],
        weeklyTargetIds = [];

      issues.forEach(({ id, user }) => {
        if (usersAttending.includes(user.id)) {
          issueIds.push(id);
        }
      });

      todos.forEach(({ id, user }) => {
        if (usersAttending.includes(user.id)) {
          todoIds.push(id);
        }
      });

      rocks.forEach(({ id, users }) => {
        if (usersAttending.includes(users[0].id)) {
          rockIds.push(id);
        }
      });

      weeklyTargets.forEach(({ id, user }) => {
        if (usersAttending.includes(user.id)) {
          weeklyTargetIds.push(id);
        }
      });

      await completeMeeting({
        variables: { id: params.meeting_id, issues: issueIds, todos: todoIds, rocks: rockIds, weeklyTargets: weeklyTargetIds },
      });
    }

    navigate(`/${params.org}`);
    setStarted(false);
  };

  const handleUpdateSuccessCriteria = (id, done) => {
    updateSuccessCriteria({ variables: { id, done } });
  };

  useEffect(() => {
    // Data is avaialble to start meeting
    if (data) {
      const { status, steps, users } = data.meeting;

      const userIds = users.map(({ id }) => id);
      if (status === "scheduled") {
        setUsersAttending(userIds);
        setCurrentStep("Start");
      } else {
        setCurrentStep(steps[0].name);
        setStarted(true);
      }

      getUserData({ variables: { organization: params.org, users: userIds } });
    }
  }, [data]);

  useEffect(() => {
    if (userData && startPolling && !isPolling) {
      startPolling(5000);
      setIsPolling(true);
    }
  }, [userData]);

  // Keep the meeting in sync with everyone watching
  useEffect(() => {
    //     if (currentSession.currentStep === "Finished" && started) {
    //       setStarted(false);
    //       return navigate(`/${params.org}/review/weekly`);
    //     }
    if (_.has(currentSessionSubscription, "meetingSub")) {
      if (currentSessionSubscription.meetingSub.currentRock !== currentRock) {
        setCurrentRock(currentSessionSubscription.meetingSub.currentRock);
      }

      if (_.isEmpty(usersAttending)) {
        setUsersAttending(currentSessionSubscription.meetingSub.users);
      }
    }
  }, [currentSessionSubscription]);

  useEffect(() => {
    // Update what room the users are in
    if (currentStep && currentStep !== "Start") {
      (async () => {
        await updateCurrentSession({ variables: { id: params.meeting_id, currentSession: { user: auth.id, currentStep } } });
      })();
    }

    if (!completedSteps.includes(currentStep)) {
      setCompletedSteps([...completedSteps, currentStep]);
    }
  }, [currentStep]);

  useEffect(() => {
    if (!_.isNil(currentRock) && !completedRocks.includes(currentRock)) {
      setCompletedRocks([...completedRocks, currentRock]);
    }
  }, [currentRock]);

  useEffect(() => {
    const tick = setInterval(() => setCurrentTime(new Date()), 1000);

    return async () => {
      await updateCurrentSession({ variables: { id: params.meeting_id, currentSession: { user: auth.id, currentStep: "leaving" } } });
      clearInterval(tick);
      window.location.reload(); // HACK: Refreshes page to prevent memory leaks when returning to meeting
    };
  }, []);

  return {
    loading,
    userLoading,
    isAdmin,
    data,
    userData,
    started,
    currentTime,
    currentStep,
    currentStepAlias,
    completedSteps,
    currentRock,
    currentSessionSubscription,
    completedRocks,
    usersAttending,
    userRooms,
    quarterInfo,
    snack,
    requestFetch,
    formatAsTime,
    updateCurrentRock,
    updateRockStatus,
    handleChangeStep,
    handleStepButton,
    handleAddDialog,
    handleAddScDialog,
    handleStart,
    handleUsersAttending,
    handleUpdateSuccessCriteria,
    handleExit,
  };
};

export default useReview;

const GET_MEETING = gql`
  query UseReview_GetMeeting($id: ID!) {
    meeting(id: $id) {
      status
      plan {
        id
      }
      users {
        id
        name {
          first
          last
        }
        profilePicture
      }
      steps {
        name
        alias
        duration
      }
      title
      owner {
        id
        name {
          first
          last
        }
        profilePicture
      }
    }
  }
`;

const SUBSCRIBE_TO_CURRENT_SESSION = gql`
  subscription ($id: ID!) {
    meetingSub(id: $id) {
      endTime
      currentStep
      currentStepEndTime
      currentRock
      users
      rooms {
        welcome
        kpIs
        rockReview
        projectUpdate
        peopleUpdate
        customerUpdate
        toDoList
        issueSolving
        wrapUp
      }
    }
  }
`;

const GET_DATA_FOR_USERS = gql`
  query UseReview_GetDataForUsers($organization: ID!, $users: [ID!], $plan: ID) {
    organization(id: $organization) {
      id
      fiscalYear
    }

    projects(organization: $organization, plan: $plan) {
      id
      value
      plan {
        id
        departmentName
        shortName
        color
      }
      notes {
        id
        date
      }
    }

    weeklyTargets(organization: $organization) {
      ...WeeklyTargetFields
    }

    rocks(organization: $organization, users: $users) {
      id
      value
      index
      status
      notes {
        id
        user {
          name {
            first
            last
          }
        }
        text
        url
        filename
        type
        date
      }
      objective {
        id
        value
      }
      users {
        ...UserFields
      }
      successCriterias {
        id
        value
        done
      }
      plan {
        id
        departmentName
        shortName
        color
        year
        plan {
          id
          year
        }
      }
      todos {
        id: _id
        createdAt
      }
    }

    todos(organization: $organization, users: $users, category: "todo", plan: $plan) {
      ...TodoFields
    }

    issues: todos(organization: $organization, users: $users, category: "issue", plan: $plan) {
      ...TodoFields
    }
  }
  ${WEEKLY_TARGET_FIELDS}
  ${USER_FIELDS}
  ${TODO_FIELDS}
`;

const UPDATE_MEETING = gql`
  mutation UseReview_UpdateMeeting($id: ID!, $status: String!) {
    updateMeeting(id: $id, status: $status)
  }
`;

const UPDATE_CURRENT_SESSION = gql`
  mutation UseReview_UpdateCurrSession($id: ID!, $currentSession: InputCurrentSession!) {
    updateCurrentSession(id: $id, currentSession: $currentSession)
  }
`;

const UPDATE_SUCCESS_CRITERIA = gql`
  mutation UseReview_UpdateSc($id: ID!, $done: Boolean!) {
    updateSuccessCriteria(id: $id, done: $done)
  }
`;

const RATE_MEETING = gql`
  mutation UseReview_RateMeeting($id: ID!, $rating: [Float!]) {
    rateMeeting(id: $id, rating: $rating)
  }
`;

const COMPLETE_MEETING = gql`
  mutation UseReview_CompleteMeeting($id: ID!, $weeklyTargets: [ID!], $todos: [ID!], $issues: [ID!], $rocks: [ID!]) {
    completeMeeting(id: $id, weeklyTargets: $weeklyTargets, todos: $todos, issues: $issues, rocks: $rocks)
  }
`;

const UPDATE_ROCK_STATUS = gql`
  mutation UseReview_UpdateRockStatus($id: ID!, $status: String) {
    updateRock(id: $id, status: $status)
  }
`;
