import React, { useState, useEffect, useMemo } from "react";
import styles from "./QuarterSummary.module.scss";
import useNotes from "../Notes/useNotes";
import _ from "lodash";
import {
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  Typography,
  TableContainer,
  Tooltip,
  List,
  ListItem,
  TextField,
  Chip,
  Button,
  ListItemText,
  Avatar,
  Collapse,
} from "@material-ui/core";

import Icon from "@mdi/react";
import { mdiFileImage, mdiFileDocumentOutline, mdiDownload } from "@mdi/js";

import { getCurrentQuarter, fullDate } from "../../utils/dates";
import { getQuarters, getDeptShortName } from "../../utils/misc";
import { isAuthed } from "../../utils/authorization";

import PlanPill from "../PlanPill/PlanPill";
import RichText from "../RichText/RichText";
import { deserialize } from "../RichText/functions";
import { Autocomplete } from "@material-ui/lab";
import DeleteConfirm from "../Notes/DeleteConfirm";
import QSNotesDialog from "./QSNotesDialog";
import SelectDepartment from "../SelectDepartment/SelectDepartment";

const NOTES_MODEL = "plan";

const QuarterSummary = ({ rocks = [], plans, closed, fiscalYear, highestReviewedQuarter, currentQuarter, user, snack }) => {
  const {
    editMode,
    handleEditMode,
    handleClose,
    handleChange,
    handleCreate,
    handleUpdate,
    handleDelete,
    handleRemoveImage,
    handleCopyImageToClipboard,
  } = useNotes({
    user,
    snack,
    model: NOTES_MODEL,
  });

  const [maxQuarter, setMaxQuarter] = useState(0);
  const [quarterTopHits, setQuarterTopHits] = useState([]);
  const [quarterTopMisses, setQuarterTopMisses] = useState([]);
  const [quarterGrades, setQuarterGrades] = useState([]);
  const [quarterNotes, setQuarterNotes] = useState([]);
  const [quarterNotesDialogIdx, setQuarterNotesDialogIdx] = useState(0);
  const [quarterNotesDialogPlans, setQuarterNotesDialogPlans] = useState([]);

  const toTitleCase = (str) => {
    return _.startCase(_.toLower(str)).trim();
  };

  const handleOpenQuarterNotesDialog =
    (note, quarterIdx = 0, plans = []) =>
    () => {
      setQuarterNotesDialogIdx(quarterIdx);
      setQuarterNotesDialogPlans(plans);
      handleEditMode(true, note)();
    };

  useEffect(() => {
    if (plans) {
      let quarterGradeGroups = [[], [], [], []];
      let quarterNoteGroups = [[], [], [], []];

      for (const plan of plans) {
        const trimmedPlan = _.pick(plan, ["id", "color", "shortName", "departmentName"]);
        const periodData = _.get(plan, "periodData", []);

        periodData.forEach((periodDatum, quarterIdx) => {
          const gradeObj = _.get(periodDatum, "periodGrade", {});
          if (!Object.values(gradeObj).some((val) => _.isNil(val))) {
            quarterGradeGroups[quarterIdx].push({ ...trimmedPlan, ...gradeObj });
          }

          const notes = _.get(periodDatum, "notes", []);
          if (!_.isEmpty(notes)) {
            quarterNoteGroups[quarterIdx].push({ ...trimmedPlan, notes });
          }
        });
      }

      setQuarterTopHits(
        quarterGradeGroups.map((quarterGradesArr) =>
          _(quarterGradesArr)
            .groupBy(({ topHit }) => `${_.toLower(topHit).trim()}`)
            .mapKeys((planObjs, grouping) => _.get(planObjs, ["0", "topHit"])) // want the original formatting partially altered  by toLower
            .value()
        )
      );

      setQuarterTopMisses(
        quarterGradeGroups.map((quarterGradesArr) =>
          _(quarterGradesArr)
            .groupBy(({ topMiss }) => `${_.toLower(topMiss).trim()}`)
            .mapKeys((planObjs, grouping) => _.get(planObjs, ["0", "topMiss"])) // want the original formatting partially altered  by toLower
            .value()
        )
      );

      setQuarterGrades(
        quarterGradeGroups.map((quarterGradesArr) => _.groupBy(quarterGradesArr, ({ grade }) => `${_.toUpper(grade).trim()}`))
      );

      setQuarterNotes(
        quarterNoteGroups.map((quarterNotesArr) =>
          _(quarterNotesArr)
            .flatMap((obj) => obj.notes.map((note) => ({ ...obj, note })))
            .groupBy(({ note }) => note.id)
            .value()
        )
      );
    }
  }, [plans]);

  useEffect(() => {
    const quarterCeiling =
      isNaN(highestReviewedQuarter) || currentQuarter > highestReviewedQuarter ? currentQuarter : highestReviewedQuarter;

    setMaxQuarter(quarterCeiling);
  }, [highestReviewedQuarter]);

  const quarterRocksData = useMemo(() => {
    let quarterOveralls = {};

    const quarterDataArr = _(rocks)
      .filter((rock) => {
        if (!_.isEmpty(plans)) {
          // The following block will only be ran on the summary page
          const rockQuarter = _.get(rock, "index");

          // const rockPlanId = _.get(rock, "plan.id");
          // const plan = _.find(plans, ["id", rockPlanId]);
          // return _.get(plan, ["periodData", (rockQuarter - 1).toString(), "reviewed"]);

          return rockQuarter <= maxQuarter;
        }
        return true;
      })
      .groupBy((rock) => {
        const user = _.get(rock, ["users", "0"]);
        const userId = _.get(user, "id");
        const firstName = toTitleCase(_.get(user, "name.first", ""));
        const lastName = toTitleCase(_.get(user, "name.last", ""));
        return `${userId}|${firstName} ${lastName}`;
      })
      .map((rocksArr, userStr) => {
        let rocksByQuarter = _(rocksArr).sortBy(["index"]).groupBy("index").value();

        let quarterStats = {};
        Object.keys(rocksByQuarter).forEach((quarterStr) => {
          const quarterRocks = rocksByQuarter[quarterStr];

          const completed = quarterRocks.filter((rock) => _.get(rock, "status") === "complete").length;
          const total = quarterRocks.length;

          quarterStats[quarterStr] = { completed, total };

          if (_.isNil(quarterOveralls[quarterStr])) {
            quarterOveralls[quarterStr] = { completed, total };
          } else {
            quarterOveralls[quarterStr]["total"] = quarterOveralls[quarterStr]["total"] + total;
            quarterOveralls[quarterStr]["completed"] = quarterOveralls[quarterStr]["completed"] + completed;
          }
        });

        let [id, name] = userStr.split("|");
        if (id === "undefined") {
          name = "Unassigned";
        }

        return { id, name, quarterStats };
      })
      .sortBy(["name"])
      .value();

    quarterDataArr.unshift({
      name: "Rocks",
      quarterStats: quarterOveralls,
    });

    return quarterDataArr;
  }, [rocks, plans, maxQuarter]);

  const isSingleQuarter = _.isNil(fiscalYear);

  const getQuarterCell = (completed = 0, total = 0) => {
    const percentage = total === 0 ? 0 : Math.round((completed / total) * 100);
    return (
      <>
        {completed}/{total} = <b>{percentage}%</b>
      </>
    );
  };

  const getGradeStatsRow = (gradeStatsArr = [], labelStr) => {
    return (
      <TableRow>
        <TableCell>{labelStr}</TableCell>
        {gradeStatsArr.map((statsDict, idx) => {
          // const allPlansSameVal = Object.keys(statsDict).length === 1;

          return (
            <TableCell align="center" key={idx}>
              <div className={styles.tdOuter}>
                {Object.keys(statsDict).map((statsVal) => {
                  const plans = statsDict[statsVal];
                  const visiblePlansLimit = 4;

                  return (
                    <div className={styles.tdInner}>
                      <div className={styles.content}>
                        <b>{statsVal}</b>
                      </div>
                      <div className={styles.plans}>
                        {/* {plans.map((plan, idx) => (
                          <PlanPill plan={plan} key={IDBIndex} noMargin></PlanPill>
                        ))} */}
                        {plans.map((plan, idx) => idx < visiblePlansLimit && <PlanPill plan={plan} noMargin />)}
                        {plans.length > visiblePlansLimit && (
                          <Tooltip
                            interactive
                            title={
                              <List className={styles.tooltipList}>
                                {plans.map(
                                  (plan, idx) =>
                                    idx >= visiblePlansLimit && (
                                      <ListItem>
                                        <PlanPill plan={plan} noMargin />
                                      </ListItem>
                                    )
                                )}
                              </List>
                            }
                          >
                            <div style={{ display: "flex" }}>
                              <PlanPill noMargin noPlanVal={`${plans.length - visiblePlansLimit} MORE`} />
                            </div>
                          </Tooltip>
                        )}
                      </div>
                    </div>
                  );
                })}
              </div>
            </TableCell>
          );
        })}
      </TableRow>
    );
  };

  const getNotesRow = () => {
    return (
      <TableRow>
        <TableCell>
          <div>Notes</div>
          <Button
            variant="contained"
            color="primary"
            onClick={handleOpenQuarterNotesDialog()}
            style={{ whiteSpace: "nowrap", marginTop: 12 }}
          >
            Add Note
          </Button>
        </TableCell>
        {quarterNotes.map((noteDict, idx) => {
          return (
            <TableCell key={idx} style={{ verticalAlign: "top" }}>
              <div className={styles.tdOuter}>
                {_.isEmpty(noteDict) ? (
                  <span>No notes at the moment</span>
                ) : (
                  Object.keys(noteDict).map((noteId) => {
                    const plans = noteDict[noteId];
                    const planIds = plans.map((plan) => plan.id);
                    const visiblePlansLimit = 4;
                    const note = _.get(plans, ["0", "note"], {});

                    const { text, url, type, filename } = note;
                    const isImg = type === "img";
                    const additionalProps = {
                      path: `periodData.${idx}`,
                    };

                    return (
                      <div className={styles.tdMid}>
                        <div className={styles.tdInner}>
                          <div className={styles.content}>
                            <RichText value={deserialize(text)} readOnly={true} noIndent />
                            {isImg && <img src={url} alt="" className={styles.image} />}
                          </div>
                          <div className={styles.plans}>
                            {plans.map((plan, idx) => idx < visiblePlansLimit && <PlanPill plan={plan} noMargin />)}
                            {plans.length > visiblePlansLimit && (
                              <Tooltip
                                interactive
                                title={
                                  <List className={styles.tooltipList}>
                                    {plans.map(
                                      (plan, idx) =>
                                        idx >= visiblePlansLimit && (
                                          <ListItem>
                                            <PlanPill plan={plan} noMargin />
                                          </ListItem>
                                        )
                                    )}
                                  </List>
                                }
                              >
                                <div style={{ display: "flex" }}>
                                  <PlanPill noMargin noPlanVal={`${plans.length - visiblePlansLimit} MORE`} />
                                </div>
                              </Tooltip>
                            )}
                          </div>
                        </div>
                        {isAuthed(user, "department facilitator") && (
                          <div className={styles.overlay}>
                            <DeleteConfirm
                              id={noteId}
                              referenceIds={planIds}
                              model={NOTES_MODEL}
                              filename={filename}
                              handleDelete={handleDelete}
                              additionalProps={additionalProps}
                              handleEdit={handleOpenQuarterNotesDialog(note, idx, planIds)}
                              handleRemoveImage={handleRemoveImage}
                              handleCopyImage={isImg ? handleCopyImageToClipboard : null}
                              note={note}
                              otherIconColor="white"
                              deleteIconColor="#ff8080"
                            />
                          </div>
                        )}
                      </div>
                    );
                  })
                )}
              </div>
            </TableCell>
          );
        })}
      </TableRow>
    );
  };

  return (
    <TableContainer className={styles.tableContainer}>
      {isSingleQuarter && quarterRocksData.length === 1 ? (
        <Typography style={{ textAlign: "center" }}>No summary data available</Typography>
      ) : (
        <Table size="small" style={isSingleQuarter ? {} : { minWidth: 1024 }}>
          {!isSingleQuarter && (
            <>
              <colgroup>
                {[...Array(5).keys()].map((idx) => (
                  <col key={idx} style={{ width: idx > 0 ? "calc(90% / 4)" : "10%", maxWidth: idx > 0 ? "calc(90% / 4)" : "10%" }} />
                ))}
              </colgroup>
              <TableHead className={styles.tableHead}>
                <TableRow>
                  <TableCell style={{ backgroundColor: "white" }} />
                  {[...Array(4).keys()].map((quarterIdx) => {
                    const isCurrentQuarter = quarterIdx + 1 === currentQuarter;
                    return (
                      <TableCell align="center" key={quarterIdx}>
                        {/* <Typography style={{ fontWeight: isCurrentQuarter ? "bold" : "normal" }}>*/}
                        <Typography>
                          Q{quarterIdx + 1} {getQuarters(fiscalYear, quarterIdx + 1)}
                        </Typography>
                      </TableCell>
                    );
                  })}
                </TableRow>
              </TableHead>
            </>
          )}
          <TableBody className={styles.tableBody}>
            {!isSingleQuarter && (
              <>
                {getGradeStatsRow(quarterTopHits, "Top Hit")}
                {getGradeStatsRow(quarterTopMisses, "Top Miss")}
                {getGradeStatsRow(quarterGrades, "Grade")}
              </>
            )}
            {quarterRocksData.map(({ id, name, quarterStats }) => {
              return (
                <TableRow key={`${id}${name}`}>
                  <TableCell>{name}</TableCell>
                  {isSingleQuarter
                    ? Object.values(quarterStats).map(({ completed, total }, idx) => (
                        <TableCell align="center" key={idx}>
                          {getQuarterCell(completed, total)}
                        </TableCell>
                      ))
                    : [...Array(4).keys()].map((quarterIdx) => {
                        const targetStats = _.get(quarterStats, (quarterIdx + 1).toString(), {});

                        return closed || quarterIdx + 1 <= maxQuarter ? (
                          <TableCell align="center" key={quarterIdx}>
                            <div>{getQuarterCell(targetStats.completed, targetStats.total)}</div>
                          </TableCell>
                        ) : (
                          <TableCell key={quarterIdx} />
                        );
                      })}
                </TableRow>
              );
            })}
            {!isSingleQuarter && getNotesRow()}
          </TableBody>
        </Table>
      )}
      <QSNotesDialog
        editMode={editMode}
        planOptions={plans}
        notePlans={quarterNotesDialogPlans}
        quarterIndex={quarterNotesDialogIdx}
        handleCreate={handleCreate}
        handleUpdate={handleUpdate}
        handleChange={handleChange}
        handleClose={handleClose}
      />
    </TableContainer>
  );
};

export default QuarterSummary;
