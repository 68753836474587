import React, { useEffect, useState, useContext } from "react";
import styles from "./SuccessCriteria.module.scss";
import _ from "lodash";
import { useMutation } from "@apollo/client";
import gql from "graphql-tag";

import useForm from "../../hooks/useForm";
import useConfirmDelete from "../../hooks/useConfirmDelete";

import { FetchContext } from "../../context/fetchContext";

import { Dialog, DialogTitle, DialogContent, DialogActions, TextField, Button, Typography } from "@material-ui/core";
import { DatePicker } from "@material-ui/pickers";

import ConfirmDeletionDialog from "../ConfirmDeletionDialog/ConfirmDeletionDialog";
import Loading from "../Loading/Loading";

const initErrorForm = {
  value: ["required"],
};

const EditDialog = ({ open, handleClose, sc = {}, snack, handleAddIssueDialog }) => {
  const { id, value, targetDate, done } = sc;

  const { requestFetch } = useContext(FetchContext);

  const [deleteSuccessCriteria] = useMutation(DELETE_SUCCESS_CRITERIA, {
    update(cache, { data: { deleteSuccessCriteria } }) {
      const { deletedSuccessCriteria } = deleteSuccessCriteria;
      const deleteScId = cache.identify(deletedSuccessCriteria);
      cache.modify({
        fields: {
          successCriterias: (existingScs) => {
            return existingScs.filter((scRef) => {
              const scId = cache.identify(scRef);
              return scId !== deleteScId;
            });
          },
        },
      });
    },
  });

  const [updateSuccessCriteria] = useMutation(UPDATE_SUCCESS_CRITERIA);

  const [submitFormAfterChange, setSubmitFormAfterChange] = useState(false);
  const [loading, setLoading] = useState(false);

  const initForm = { value, targetDate, done };
  const { form, formErrors, handleChange, handleChangeManual, handleChangeDate, resetForm, validateForm } = useForm({
    initForm,
    initErrorForm,
  });

  const itemType = "success criteria";
  const { confirmOpen, handleConfirmOpen, handleDelete } = useConfirmDelete({
    id,
    value: form.value,
    itemType,
  });

  const handleChangeComplete = () => {
    setSubmitFormAfterChange(true);
    handleChangeManual({ name: "done", value: !form.done });
  };

  const handleSubmit = async () => {
    if (!validateForm()) return;

    setLoading(true);

    const { value, targetDate, done } = form;

    try {
      const ok = await updateSuccessCriteria({ variables: { id, value, targetDate, done } });

      if (ok) {
        snack(`Updated "${value}" success criteria`);
        requestFetch();
        handleClose();
      }
    } catch (err) {
      snack("Failed to update success criteria", "error");
    }

    setLoading(false);
  };

  const handleDeleteEvent = async () => {
    let successfulDeletion = await handleDelete(deleteSuccessCriteria)();
    if (successfulDeletion) {
      handleClose();
    }
  };

  useEffect(() => {
    if (submitFormAfterChange) {
      setSubmitFormAfterChange(false);
      handleSubmit();
    }
  }, [form]);

  useEffect(() => {
    if (open) resetForm(initForm);
  }, [id, open]);

  return (
    <>
      <Dialog
        open={open}
        onClose={(event, reason) => {
          if (reason !== "backdropClick") {
            handleClose();
          }
        }}
        fullWidth
      >
        <DialogTitle>Edit Success Criteria</DialogTitle>
        <DialogContent>
          <Typography className={styles.label}>
            Status: <span className={done ? styles.complete : styles.incomplete}>{done ? "Complete" : "Incomplete"}</span>
          </Typography>
          <TextField
            autoFocus
            label="Success Criteria Description"
            name="value"
            fullWidth
            variant="outlined"
            margin="normal"
            value={form.value || ""}
            onChange={handleChange}
            multiline
            helperText={formErrors.value}
            error={Boolean(formErrors.value)}
          />
          <DatePicker
            autoOk
            clearable
            value={form.targetDate}
            onChange={handleChangeDate("targetDate")}
            fullWidth
            inputVariant="outlined"
            format="dd/MM/yyyy"
            margin="normal"
            label="Target Date (optional)"
          />
        </DialogContent>
        <DialogActions>
          {handleAddIssueDialog && (
            <Button onClick={handleAddIssueDialog(id, "SuccessCriteria", form.value)} color="primary">
              Add Issue
            </Button>
          )}
          <Button onClick={handleChangeComplete} color="primary" style={{ marginRight: "auto" }}>
            {submitFormAfterChange || loading ? <Loading size={24} color="primary" /> : `Mark as ${done ? "Incomplete" : "Complete"}`}
          </Button>
          <Button onClick={handleClose}>Cancel</Button>
          <Button onClick={handleConfirmOpen(true)} className={styles.delete}>
            Delete
          </Button>
          <Button color="primary" onClick={handleSubmit}>
            {loading ? <Loading size={24} color="primary" /> : "Save"}
          </Button>
        </DialogActions>
      </Dialog>

      <ConfirmDeletionDialog
        itemType={itemType}
        value={form.value}
        confirmOpen={confirmOpen}
        handleConfirmOpen={handleConfirmOpen}
        handleDeletion={handleDeleteEvent}
      />
    </>
  );
};

export default EditDialog;

const DELETE_SUCCESS_CRITERIA = gql`
  mutation DialogDeleteSuccessCriteria($id: ID!) {
    deleteSuccessCriteria(id: $id) {
      deletedSuccessCriteria {
        id
      }
      updatedRock {
        id
        successCriterias {
          id
        }
      }
    }
  }
`;

const UPDATE_SUCCESS_CRITERIA = gql`
  mutation ($id: ID!, $value: String, $targetDate: String, $done: Boolean) {
    updateSuccessCriteria(id: $id, value: $value, targetDate: $targetDate, done: $done)
  }
`;
