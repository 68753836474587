import React, { useState, useEffect, useContext } from "react";
import { useAuth } from "../../context/authContext";
import { useDepartmentFilter } from "../../context/departmentFilterContext";
import styles from "./AddRockDialog.module.scss";
import _ from "lodash";
import { useQuery, useMutation } from "@apollo/client";
import gql from "graphql-tag";
import useForm from "../../hooks/useForm";
import {
  Button,
  ButtonGroup,
  MenuItem,
  TextField,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  useMediaQuery,
  Typography,
} from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import Loading from "../Loading/Loading";
import SelectObjectives from "../SelectObjectives/SelectObjectives";
import SelectQuarter from "../SelectQuarter/SelectQuarter";
import SelectDepartment from "../SelectDepartment/SelectDepartment";
import SelectUsers from "../SelectUsers/SelectUsers";
import Menu from "../Menu/Menu";
import SuccessCriteriaList from "../Rock/SuccessCriteriaList";
import { ROCK_FIELDS } from "../../utils/fragments";
import { GET_QUARTER_ROCKS } from "../../utils/query";

const initForm = {
  value: null,
  index: 1,
  users: [],
  objective: null,
  plan: null,
  successCriterias: [],
};

const initErrorForm = {
  value: ["required"],
  // objective: ["required"],
};

const options = ["Create", "Create & Add Another", "Create & Add Success Criteria"];

const AddRockDialog = ({ dialog, setDialog, params, fetch, snack }) => {
  const { auth } = useAuth();
  const { departmentFilter } = useDepartmentFilter();

  // if undefined then rock is being created from QuarterRocks page otherwise it is being created from the OneYear page
  const variables = _.get(dialog, "addRockDialog.variables");
  const [createRock, { loading }] = useMutation(CREATE_ROCK, {
    update(cache, { data: { createRock } }) {
      try {
        if (!_.isNil(variables)) {
          const existingData = cache.readQuery({
            query: GET_QUARTER_ROCKS,
            variables,
            // returnPartialData: true, // use cache.diff() instead of cache.readQuery() to investigate the actual error
          });
          cache.writeQuery({
            query: GET_QUARTER_ROCKS,
            variables,
            data: {
              rocks: [...existingData.rocks, createRock.newRock],
            },
          });
        }
      } catch (e) {
        console.log(e);
      }
    },
  });

  const deptFilterSpid = _.get(departmentFilter, "sharedPlanId");
  const [selectedOption, setSelectedOption] = useState(options[0]);

  const {
    data,
    loading: queryLoading,
    refetch,
  } = useQuery(GET_USERS_PLANS_AND_OBJECTIVES, {
    variables: { organization: params.org, sharedPlanId: deptFilterSpid, corpPlan: _.get(dialog, "addRockDialog.planId", null) },
  });

  const { form, formErrors, handleChange, handleChangeManual, resetForm, validateForm } = useForm({
    initForm,
    initErrorForm,
  });

  const fs = useMediaQuery("(max-width: 600px)");

  const hasObjectiveId = dialog.addRockDialog.open !== true;
  const departmentName = _.get(dialog, "addRockDialog.department");

  const handleSelectedOption = (option) => () => {
    setSelectedOption(option);
  };

  const handleSubmit = async () => {
    if (!validateForm() && !hasObjectiveId) return;

    const { value, index, users, objective, duplicate, plan, successCriterias } = form;
    try {
      const ok = await createRock({
        variables: {
          organization: params.org,
          objective: hasObjectiveId ? dialog.addRockDialog.open : objective,
          value,
          index,
          users,
          duplicate,
          plan,
          successCriteria: successCriterias.map((sc) => _.pick(sc, ["id", "value", "targetDate", "done"])),
        },
      });

      if (ok.data.createRock) {
        snack(`Created "${value}" rock`);
        resetForm({ ...initForm, index });
        if (selectedOption !== "Create & Add Another") {
          handleClose();

          if (selectedOption === "Create & Add Success Criteria") {
            setDialog({ ...dialog, addSuccessCriteriaDialog: ok.data.createRock });
          }
        }
      }
    } catch (err) {
      snack("Failed to create rock", "error");
    }
  };

  const handleClose = () => {
    setDialog({ ...dialog, addRockDialog: { open: false, quarter: 1, value: null, objective: null, duplicate: null } });
  };

  useEffect(() => {
    if (dialog.addRockDialog.open) {
      let newForm = { ...initForm };
      newForm.value = dialog.addRockDialog.value;
      newForm.index = dialog.addRockDialog.quarter;
      newForm.objective = dialog.addRockDialog.objective;
      newForm.duplicate = dialog.addRockDialog.duplicate;
      newForm.successCriterias = dialog.addRockDialog.successCriterias;
      if (deptFilterSpid && !queryLoading && form.duplicate) {
        const currentPlan = _.find(data.plans, (plan) => plan.sharedPlanId === deptFilterSpid);
        newForm.plan = _.get(currentPlan, "id");
      }

      resetForm(newForm);
    }
  }, [dialog.addRockDialog, deptFilterSpid, queryLoading, form.duplicate]);

  useEffect(() => {
    if (dialog.addRockDialog.open) {
      refetch();
    }
  }, [fetch, dialog.addRockDialog.open]);

  const getSelectedPlan = () => {
    // if there exists a selected plan/department from the dropdown, return it
    let selectedPlan = form.plan;
    if (selectedPlan) {
      return selectedPlan;
    }

    // otherwise grab the plan of the objective associated with the rock
    let objs = _.get(data, "objectives", []);
    let selectedObj = _.find(objs, ["id", hasObjectiveId && !form.duplicate ? dialog.addRockDialog.open : form.objective]);
    return _.get(selectedObj, "plan.id");
  };

  return (
    <Dialog
      open={dialog.addRockDialog.open}
      onClose={(event, reason) => {
        if (reason !== "backdropClick") {
          handleClose();
        }
      }}
      fullWidth
      fullScreen={fs}
    >
      <DialogTitle>
        <div className={styles.title}>
          Create New Rock
          <div>
            <IconButton onClick={handleClose} size="small">
              <CloseIcon fontSize="inherit" />
            </IconButton>
          </div>
        </div>
      </DialogTitle>
      <DialogContent>
        <TextField
          autoFocus
          label="Rock Description"
          name="value"
          fullWidth
          variant="outlined"
          margin="normal"
          multiline
          value={form.value || ""}
          onChange={handleChange}
          helperText={formErrors.value}
          error={Boolean(formErrors.value)}
        />
        <SelectQuarter name="index" value={form.index} handleChange={handleChange} helperText="Which quarter is this rock for?" />
        {!hasObjectiveId && (
          <SelectObjectives
            name="objective"
            objectives={_.get(data, "objectives", null)}
            handleChange={handleChange}
            values={[form.objective]}
            category="rock"
            helperText={formErrors.objective}
            error={Boolean(formErrors.objective)}
            plansOrder={_.get(data, "organization.plansOrder")}
            showEmpty={true}
          />
        )}
        {form.duplicate && (
          <SelectDepartment
            showAll
            plans={_.get(data, "plans")}
            name="plan"
            handleChange={handleChange}
            value={form.plan}
            helperText={formErrors.plan}
            error={Boolean(formErrors.plan)}
            disabled={!_.isNil(deptFilterSpid)}
          />
        )}

        <SelectUsers
          name="users"
          users={_.get(data, "users")}
          handleChange={handleChange}
          values={form.users}
          helperText="Who is responsible for this rock?"
          plan={getSelectedPlan()}
          allPlans={_.get(data, "plans")}
        />

        <SuccessCriteriaList successCriterias={form.successCriterias} onChange={handleChangeManual} />

        {departmentName && (
          <Typography>
            <span className={styles.label}>Creating Rock from Department:</span> <b>{departmentName}</b>
          </Typography>
        )}
      </DialogContent>
      <DialogActions>
        <ButtonGroup variant="contained" color="primary">
          <Button onClick={handleSubmit}>{loading ? <Loading size={24} color="#fff" /> : selectedOption}</Button>
          <Button color="primary" size="small">
            <Menu icon="arrow" color="#fff">
              {options.map((option) => (
                <MenuItem key={option} onClick={handleSelectedOption(option)}>
                  {option}
                </MenuItem>
              ))}
            </Menu>
          </Button>
        </ButtonGroup>
      </DialogActions>
    </Dialog>
  );
};

export default AddRockDialog;

const GET_USERS_PLANS_AND_OBJECTIVES = gql`
  query AddRockDialog_GetUsersPlansObjectives($organization: ID!, $sharedPlanId: ID, $corpPlan: ID) {
    organization(id: $organization) {
      id
      plansOrder
    }

    users(organization: $organization) {
      name {
        first
        last
      }
      profilePicture
      id
      plan {
        id
        departmentName
        sharedPlanId
      }
    }

    objectives(organization: $organization, sharedPlanId: $sharedPlanId, corpPlan: $corpPlan) {
      id
      value
      category
      number
      plan {
        id
        departmentName
        color
        sharedPlanId
        shortName
      }
    }

    plans(organization: $organization, category: "1 year", oneYearCorpPlan: $corpPlan) {
      id
      departmentName
      sharedPlanId
    }
  }
`;

const CREATE_ROCK = gql`
  ${ROCK_FIELDS}
  mutation CreateQuarterRock(
    $value: String!
    $index: Int!
    $organization: ID!
    $objective: ID
    $users: [ID!]
    $duplicate: ID
    $plan: ID
    $successCriteria: [SuccessCriterionInput]
  ) {
    createRock(
      value: $value
      index: $index
      organization: $organization
      objective: $objective
      users: $users
      duplicate: $duplicate
      plan: $plan
      successCriteria: $successCriteria
    ) {
      newRock {
        ...RockFields
        objective {
          id
          value
        }
      }
      updatedObjective {
        id
        rocks {
          ...RockFields
        }
      }
      updatedPlan {
        id
        rocks {
          id
        }
      }
    }
  }
`;
