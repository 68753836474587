import React, { useEffect, useContext } from "react";
import { useAuth } from "../../context/authContext";
import { useDepartmentFilter } from "../../context/departmentFilterContext";
import styles from "./AddSuccessCriteriaDialog.module.scss";
import _ from "lodash";
import { useQuery, useMutation } from "@apollo/client";
import gql from "graphql-tag";
import useForm from "../../hooks/useForm";
import { Button, TextField, Dialog, DialogActions, DialogContent, DialogTitle, IconButton, useMediaQuery } from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import Loading from "../Loading/Loading";
import SelectRock from "../SelectRock/SelectRock";
import { SUCCESS_CRITERIA_FIELDS } from "../../utils/fragments";

const initForm = {
  value: null,
  rock: null,
};

const initErrorForm = {
  value: ["required"],
  rock: ["required"],
};

const AddSuccessCriteriaDialog = ({ dialog, setDialog, params, snack }) => {
  const { auth } = useAuth();
  const { departmentFilter } = useDepartmentFilter();

  const { data, refetch } = useQuery(GET_ROCKS, { variables: { organization: params.org } });
  const deptFilterSpid = _.get(departmentFilter, "sharedPlanId");
  const [createSuccessCriteria, { loading }] = useMutation(CREATE_SUCCESS_CRITERIA);
  const { form, formErrors, handleChange, resetForm, validateForm } = useForm({
    initForm,
    initErrorForm,
  });

  const fs = useMediaQuery("(max-width: 600px)");

  const handleSubmit = (close) => async () => {
    if (!validateForm()) return;

    const { value, rock } = form;
    const rockId = dialog.addSuccessCriteriaDialog === true ? rock : dialog.addSuccessCriteriaDialog;

    try {
      const ok = await createSuccessCriteria({
        variables: {
          organization: params.org,
          rock: rockId,
          value,
        },
      });

      if (ok) {
        snack(`Created "${value}" success criteria`);
        // If this dialog was opened in with a rock as a reference, do not remove the selected rock from the form
        if (dialog.addSuccessCriteriaDialog !== true) {
          resetForm({ ...form, value: null });
        } else {
          resetForm();
        }

        if (close) {
          handleClose();
        }
      }
    } catch (err) {
      snack("Failed to create succcess criteria", "error");
    }
  };

  const handleClose = () => {
    setDialog({ ...dialog, addSuccessCriteriaDialog: false });
  };

  useEffect(() => {
    refetch();
  }, [dialog.addSuccessCriteriaDialog]);

  return (
    <Dialog
      open={Boolean(dialog.addSuccessCriteriaDialog)}
      onClose={(event, reason) => {
        if (reason !== "backdropClick") {
          handleClose();
        }
      }}
      fullWidth
      fullScreen={fs}
    >
      <DialogTitle>
        <div className={styles.title}>
          Create New Success Criteria
          <div>
            <IconButton onClick={handleClose} size="small">
              <CloseIcon fontSize="inherit" />
            </IconButton>
          </div>
        </div>
      </DialogTitle>
      <DialogContent>
        <TextField
          autoFocus
          label="Success Criteria Description"
          name="value"
          fullWidth
          variant="outlined"
          margin="normal"
          multiline
          value={form.value || ""}
          onChange={handleChange}
          helperText={formErrors.value}
          error={Boolean(formErrors.value)}
        />

        <SelectRock
          name="rock"
          rocks={_.get(data, "rocks", null)}
          handleChange={handleChange}
          value={form.rock}
          defaultRock={dialog.addSuccessCriteriaDialog === true ? null : dialog.addSuccessCriteriaDialog}
          helperText={formErrors.rock}
          error={Boolean(formErrors.rock)}
          sharedPlanId={deptFilterSpid}
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={handleSubmit(false)} color="primary" variant="outlined" disabled={loading} className={styles.button}>
          {loading ? <Loading size={24} color="#fff" /> : "Create & Add Another"}
        </Button>
        <Button onClick={handleSubmit(true)} color="primary" variant="contained" disabled={loading} className={styles.button}>
          {loading ? <Loading size={24} color="#fff" /> : "Create"}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default AddSuccessCriteriaDialog;

const GET_ROCKS = gql`
  query AddScDialog_GetRocks($organization: ID!) {
    rocks(organization: $organization) {
      id
      value
      plan {
        id
        departmentName
        color
        shortName
        sharedPlanId
      }
    }
  }
`;

const CREATE_SUCCESS_CRITERIA = gql`
  ${SUCCESS_CRITERIA_FIELDS}
  mutation CreateSuccessCriteria($value: String!, $rock: ID!, $organization: ID!) {
    createSuccessCriteria(value: $value, rock: $rock, organization: $organization) {
      updatedRock {
        id
        successCriterias {
          ...SuccessCriteriaFields
        }
      }
    }
  }
`;
